import React, { useContext, useState, useEffect } from 'react'
import { Grid, Card, CardActionArea, Typography, CardContent, FormControlLabel, RadioGroup, Radio, Tooltip, Avatar, CircularProgress } from '@material-ui/core'
import { ShareOutlined, PeopleOutlined, PaymentOutlined, MoneyOutlined } from '@material-ui/icons'
import Image from 'components/Image'
import size from 'lodash/size'
import dayjs from 'dayjs'
import { navigate } from 'gatsby'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Context from 'reactContext'
import loadable from '@loadable/component'
import { trafficIndex, getTrafficByDay } from 'http/self'
import SnackMessage from 'components/snackbar'
import Main from 'components/main'
import { post } from 'utils/request'

const CompanyDayTrafficLineChart = loadable(() => import('components/CompanyDayTraffic'), { ssr: false })
const MemberMonthTrafficCountPieChart = loadable(() => import('components/MemberMonthTrafficCount'), { ssr: false })
const MemberMonthTrafficProportionBarChart = loadable(() => import('components/MemberMonthTrafficProportion'), { ssr: false })
const CompanyMonthTrafficBarChart = loadable(() => import('components/CompanyMonthTraffic'), { ssr: false })

const Overview = () => {
  const { self } = useContext(Context)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [currentYear, setCurrentYear] = useState(dayjs().year())
  const [currentMonth, setCurrentMonth] = useState(dayjs().month() + 1)
  const [currentDay, setCurrentDay] = useState(dayjs().date())
  const [resMessage, setResMessage] = useState('')
  const [chargeAmount, setChargeAmount] = useState(500)
  const [traffic, setTraffic] = useState({})
  const [dayTraffic, setDayTraffic] = useState([])
  const recharge = async (amount, channel) => {
    const res = await post('managers/payments/recharge', { amount, channel })
    if (res.ok) {
      window.location = res.url
    }
  }

  const onPay = (channel) => {
    recharge(Math.round(chargeAmount * 100), channel)
  }

  const paymentsPanel = () => {
    return (
      <Card style={{ flex: '0 0 100%' }}>
        <CardContent>
          <div className="font-bold text-base">支付方式</div>
          <div
            role="presentation"
            onClick={() => {
              setLoading(true)
              onPay('mugglepay')
            }}
            className="cursor-pointer"
          >
            {loading ? (
              <div className="text-center">
                <CircularProgress size={30} />
              </div>
            ) : (
              <div className="qiyetong">
                <div className="qiyetong-payment mt-6 w-48">
                  <Image className="qiyetong-pay" fileName="alipay.png" alt="" />
                </div>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    )
  }

  const quickChargePanel = () => {
    return (
      <RadioGroup row value={chargeAmount}>
        {[
          [300, 300],
          [500, 550],
          [1000, 1150],
          [2000, 2400],
          [5000, 6000],
          [10000, 12000],
        ].map((v) => (
          <Tooltip title={`充值${v[0]}送${self.get('agent_id') ? 0 : v[1] - v[0]}`} key={v[0]} placement="top">
            <FormControlLabel key={v} value={v[0]} onChange={() => setChargeAmount(v[0])} control={<Radio />} label={`¥${v[0]}`} />
          </Tooltip>
        ))}
      </RadioGroup>
    )
  }

  const accountSections = () => {
    const balance = self.get('balance', 0) / 100
    const totalCharge = self.get('total_charge', 0) / 100
    const shortId = self.get('short_id', 0)
    const seats = self.getIn(['settings', 'seats'], 0)
    const cardProps = [
      [
        '活跃成员',
        '/main/users',
        <Avatar className="h-12 w-12 float-right -mt-12 text-white bg-green-400">
          <PeopleOutlined />
        </Avatar>,
        `${seats}`,
      ],
      [
        '支付总额',
        '/main/payments',
        <Avatar className="h-12 w-12 float-right -mt-12 text-white bg-red-400">
          <MoneyOutlined />
        </Avatar>,
        `¥ ${totalCharge}`,
        2,
      ],
      [
        '剩余金额',
        '/main/fees',
        <Avatar className="h-12 w-12 float-right -mt-12 text-white bg-orange-400">
          <PaymentOutlined />
        </Avatar>,
        `¥ ${balance}`,
        2,
      ],
    ]
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7} className="flex">
            <Card style={{ flex: '0 0 100%' }}>
              <CardContent>
                <div className="font-bold text-base mb-6">企业充值</div>
                {quickChargePanel()}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={5} className="flex">
            {paymentsPanel()}
          </Grid>
        </Grid>
        <div className="py-3">
          <Grid container spacing={2}>
            {cardProps.map(([title, path, icon, cardText]) => (
              <Grid item key={title} xs={12} sm={6} lg={3}>
                <CardActionArea>
                  <Card className="cursor-pointer" onClick={() => navigate(path)}>
                    <CardContent className="p-6">
                      <Typography className="text-base">{title}</Typography>
                      <Typography className="text-xl mt-3 font-bold">{cardText}</Typography>
                      {icon}
                    </CardContent>
                  </Card>
                </CardActionArea>
              </Grid>
            ))}
            <Grid item xs={12} sm={6} lg={3}>
              <CopyToClipboard
                text={shortId}
                onCopy={() => {
                  setOpen(true)
                  setResMessage('复制成功')
                }}
              >
                <CardActionArea>
                  <Card className="cursor-pointer">
                    <CardContent className="p-6">
                      <Typography className="text-base">邀请码 (点击复制)</Typography>
                      <Typography className="text-xl mt-3 font-bold">{shortId}</Typography>
                      <Avatar className="h-12 w-12 float-right -mt-12 text-white bg-blue-400">
                        <ShareOutlined />
                      </Avatar>
                    </CardContent>
                  </Card>
                </CardActionArea>
              </CopyToClipboard>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }

  const changeDay = (day) => {
    getTrafficByDay(currentYear, currentMonth, day.split('-')[2]).then((body) => {
      const { ok, traffics } = body
      if (ok) {
        setDayTraffic(traffics)
      }
    })
    setCurrentDay(day.split('-')[2])
  }

  useEffect(() => {
    trafficIndex(currentYear, currentMonth).then((body) => {
      const { ok, traffics } = body
      if (ok) {
        setTraffic(traffics)
        setCurrentMonth(currentMonth)
        setCurrentYear(currentYear)
      }
    })
  }, [])

  useEffect(() => {
    getTrafficByDay(currentYear, currentMonth, currentDay).then((body) => {
      const { ok, traffics } = body
      if (ok) {
        setDayTraffic(traffics)
        setCurrentMonth(currentMonth)
        setCurrentYear(currentYear)
        setCurrentDay(currentDay)
      }
    })
  }, [])

  return (
    <Main>
      {accountSections()}
      <div className="font-bold text-base my-3">当月流量统计（点击查看数据）</div>
      {size(traffic) === 0 ? null : <CompanyMonthTrafficBarChart data={traffic[currentYear][currentMonth]} onClick={changeDay} />}
      <div className="font-bold text-base my-3">{`${currentMonth}月${currentDay}日流量统计（点击查看数据）`}</div>
      <CompanyDayTrafficLineChart data={dayTraffic} />
      <div className="font-bold text-base my-3">成员流量使用</div>
      {size(traffic) === 0 ? null : <MemberMonthTrafficProportionBarChart data={traffic[currentYear][currentMonth]} />}
      <div className="font-bold text-base my-3">流量使用占比</div>
      {size(traffic) === 0 ? null : <MemberMonthTrafficCountPieChart data={traffic[currentYear][currentMonth]} />}
      <SnackMessage open={open} onClose={() => setOpen(false)} message={resMessage} status="success" />
    </Main>
  )
}

export default Overview
